import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutElMirageCustom"
import BgImg from "../../../images/Hebrews_Big.jpg"
import Hebrews from "../../../images/Hebrews_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Hebrews"
    SecondText="Check out sermons from our Hebrews sermon series"
  >
    <SEO title="Sermons - Hebrews" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
                                 <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Lessons for Life"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/lessons-for-life"
        date="July 21st, 2024"
        pastor="Pastor Tyler"
        badge="NEW"
        badgeColor="teal"
      />
                             <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Disciplined Life"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/the-disciplined-life"
        date="July 14th, 2024"
        pastor="Pastor Tyler"
      />
                         <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Heroes"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/heroes"
        date="July 7th, 2024"
        pastor="Pastor Tyler"
      />
                     <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Persevere for the Promise"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/persevere-for-the-promise"
        date="June 30th, 2024"
        pastor="Lyle Harrenstein"
      />
                 <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Earning and Exuding"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/earning-and-exuding"
        date="June 23rd, 2024"
        pastor="Pastor Tyler"
      />
             <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Old vs. New"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/old-vs-new"
        date="June 16th, 2024"
        pastor="Pastor Tyler"
      />
         <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Needs and Nostalgia"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/needs-and-nostalgia"
        date="June 9th, 2024"
        pastor="Lyle Harrenstein"
      />
     <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Priest, Perfection, and Position"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/priest-perfection-position"
        date="June 2nd, 2024"
        pastor="Pastor Tyler"
      />
     <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Growth and Goals"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/growth-and-goals"
        date="May 26th, 2024"
        pastor="Pastor Tyler"
      />
                      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus the Great High Priest"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-the-great-high-priest"
        date="May 19th, 2024"
        pastor="Pastor Tyler"
      />
                  <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus our Rest"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-our-rest"
        date="May 12th, 2024"
        pastor="Pastor Tyler"
        badge="NEW"
        badgeColor="teal"
      />
              <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus and our Heroes"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-and-our-heroes"
        date="May 5th, 2024"
        pastor="Pastor Tyler"
      />
          <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus Our Brother"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-our-brother"
        date="April 28th, 2024"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus as the Foundation"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-as-the-foundation"
        date="April 21st, 2024"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus > Angels"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-over-angels"
        date="April 14th, 2024"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus is Greater"
        sermonSeries="Hebrews"
        sermonLink="/elmirage/sermons/jesus-is-greater"
        date="April 7th, 2024"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/elmirage/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church El Mirage"
      />
    </Grid>
  </Layout>
)

export default IndexPage
